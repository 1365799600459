@font-face {
  font-family: "gentona";
  src: url("/fonts/rene_bieder_-_gentona_book-webfont.eot?iefix") format("embedded-opentype"), url("/fonts/rene_bieder_-_gentona_book-webfont.woff") format("woff"), url("/fonts/rene_bieder_-_gentona_book-webfont.ttf") format("truetype"), url("/fonts/rene_bieder_-_gentona_book-webfont.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "gentona-bold";
  src: url("/fonts/rene_bieder_-_gentona_bold-webfont.eot");
  src: url("/fonts/rene_bieder_-_gentona_bold-webfont.eot?iefix") format("embedded-opentype"), url("/fonts/rene_bieder_-_gentona_bold-webfont.woff") format("woff"), url("/fonts/rene_bieder_-_gentona_bold-webfont.ttf") format("truetype"), url("/fonts/rene_bieder_-_gentona_bold-webfont.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}
.ufl-font--sans {
  font-family: "gentona", Arial, sans-serif;
}

.ufl-font--sans--bold {
  font-family: "gentona-bold", Arial, sans-serif;
}

.ufl-font--serif {
  font-family: "quadonmedium", Arial, serif;
}

.navbar-default {
  font-family: "gentona", Arial, sans-serif;
  background-color: #00529b;
  border: 0;
  transition: 0.5s;
}
.navbar-default .logo {
  display: inline-block;
  float: left;
  background-color: #00529b;
  padding: 5px;
}
.navbar-default .logo img {
  height: 55px;
  width: 55px;
}
.navbar-default .navbar-brand {
  font-family: "gentona", Arial, sans-serif;
  color: #00529b;
  padding: 24px 0 0 15px;
  letter-spacing: 1px;
  font-weight: 700;
  text-transform: uppercase;
  margin-left: 0;
}

.navbar {
  min-height: 50px;
  margin-bottom: 20px;
  display: block;
}

.navbar-fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  top: 0;
}

.collapse {
  display: none;
}

.navbar-collapse.in {
  overflow-y: auto;
}

.collapse.in {
  display: block;
}

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

a {
  background-color: transparent;
}

a:active,
a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code,
pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type=checkbox],
input[type=radio] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  height: auto;
}

input[type=search] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333333;
  background-color: #ffffff;
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  color: #337ab7;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #23527c;
  text-decoration: underline;
}

a:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

figure {
  margin: 0;
}

img {
  vertical-align: middle;
}

.padded {
  padding: 2em;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eeeeee;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

[role=button] {
  cursor: pointer;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 769px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 1026px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1201px) {
  .container {
    width: 1170px;
  }
}
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}


.col-md-2,
.col-sm-4,
.col-md-4,
.col-md-5,
.col-sm-6,
.col-md-6,
.col-md-7,
.col-sm-8,
.col-md-8,
.col-xs-12,
.col-sm-12,
.col-md-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}


.col-xs-12 {
  float: left;
}

.col-xs-12 {
  width: 100%;
}

@media (min-width: 769px) {
  
  .col-sm-4,
  .col-sm-6,
  .col-sm-8,
  .col-sm-12 {
    float: left;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
}
@media (min-width: 1026px) {
  
  .col-md-2,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-12 {
    float: left;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-push-2 {
    left: 16.66666667%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
}
fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  color: #333333;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type=search] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

input[type=radio],
input[type=checkbox] {
  margin: 4px 0 0;
  margin-top: 1px \9 ;
  line-height: normal;
}

input[type=file] {
  display: block;
}

input[type=range] {
  display: block;
  width: 100%;
}

select[multiple],
select[size] {
  height: auto;
}

input[type=file]:focus,
input[type=radio]:focus,
input[type=checkbox]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

output {
  display: block;
  padding-top: 7px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555555;
}

input[type=search] {
  -webkit-appearance: none;
}

.radio,
.checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.radio label,
.checkbox label {
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}

.radio input[type=radio],
.checkbox input[type=checkbox] {
  position: absolute;
  margin-left: -20px;
  margin-top: 4px \9 ;
}

.radio + .radio,
.checkbox + .checkbox {
  margin-top: -5px;
}

input[type=radio][disabled],
input[type=checkbox][disabled],
input[type=radio].disabled,
input[type=checkbox].disabled,
fieldset[disabled] input[type=radio],
fieldset[disabled] input[type=checkbox] {
  cursor: not-allowed;
}

.radio.disabled label,
.checkbox.disabled label,
fieldset[disabled] .radio label,
fieldset[disabled] .checkbox label {
  cursor: not-allowed;
}


.dropdown {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

.dropdown-menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333333;
  white-space: nowrap;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  text-decoration: none;
  color: #262626;
  background-color: #f5f5f5;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  background-color: #337ab7;
}

.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  color: #777777;
}

.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  cursor: not-allowed;
}

.open > .dropdown-menu {
  display: block;
}

.open > a {
  outline: 0;
}

.dropdown-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 990;
}

@media (min-width: 1026px) {
  .navbar-right .dropdown-menu {
    left: auto;
    right: 0;
  }
}
.nav {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.nav > li {
  position: relative;
  display: block;
}

.nav > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
}

.nav > li > a:hover,
.nav > li > a:focus {
  text-decoration: none;
  background-color: #eeeeee;
}

.nav > li.disabled > a {
  color: #777777;
}

.nav > li.disabled > a:hover,
.nav > li.disabled > a:focus {
  color: #777777;
  text-decoration: none;
  background-color: transparent;
  cursor: not-allowed;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: #eeeeee;
  border-color: #337ab7;
}

.nav > li > a > img {
  max-width: none;
}

.navbar {
  position: relative;
  min-height: 50px;
  margin-bottom: 20px;
  border: 1px solid transparent;
}

@media (min-width: 1026px) {
  .navbar {
    border-radius: 4px;
  }
}
@media (min-width: 1026px) {
  .navbar-header {
    float: left;
  }
}
.navbar-collapse {
  overflow-x: visible;
  padding-right: 15px;
  padding-left: 15px;
  border-top: 1px solid transparent;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  -webkit-overflow-scrolling: touch;
}

.navbar-collapse.in {
  overflow-y: auto;
}

@media (min-width: 1026px) {
  .navbar-collapse {
    width: auto;
    border-top: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .navbar-collapse.collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important;
  }
  .navbar-collapse.in {
    overflow-y: visible;
  }
  .navbar-fixed-top .navbar-collapse {
    padding-left: 0;
    padding-right: 0;
  }
}
.navbar-fixed-top .navbar-collapse {
  max-height: 340px;
}

@media (max-device-width: 480px) and (orientation: landscape) {
  .navbar-fixed-top .navbar-collapse {
    max-height: 200px;
  }
}
.container > .navbar-header,
.container-fluid > .navbar-header,
.container > .navbar-collapse,
.container-fluid > .navbar-collapse {
  margin-right: -15px;
  margin-left: -15px;
}

@media (min-width: 1026px) {
  .container > .navbar-header,
  .container-fluid > .navbar-header,
  .container > .navbar-collapse,
  .container-fluid > .navbar-collapse {
    margin-right: 0;
    margin-left: 0;
  }
}
.navbar-fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
}

@media (min-width: 1026px) {
  .navbar-fixed-top {
    border-radius: 0;
  }
}
.navbar-fixed-top {
  top: 0;
  border-width: 0 0 1px;
}

.navbar-brand {
  float: left;
  padding: 15px 15px;
  font-size: 18px;
  line-height: 20px;
  height: 50px;
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-brand > img {
  display: block;
}

@media (min-width: 1026px) {
  .navbar > .container .navbar-brand,
  .navbar > .container-fluid .navbar-brand {
    margin-left: -15px;
  }
}
.navbar-toggle {
  position: relative;
  float: right;
  margin-right: 15px;
  padding: 9px 10px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.navbar-toggle:focus {
  outline: 0;
}

.navbar-toggle .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
}

.navbar-toggle .icon-bar + .icon-bar {
  margin-top: 4px;
}

@media (min-width: 1026px) {
  .navbar-toggle {
    display: none;
  }
}
.navbar-nav {
  margin: 7.5px -15px;
}

.navbar-nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 20px;
}

@media (max-width: 1025px) {
  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .navbar-nav .open .dropdown-menu > li > a {
    padding: 5px 15px 5px 25px;
  }
  .navbar-nav .open .dropdown-menu > li > a {
    line-height: 20px;
  }
  .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-nav .open .dropdown-menu > li > a:focus {
    background-image: none;
  }
}
@media (min-width: 1026px) {
  .navbar-nav {
    float: left;
    margin: 0;
  }
  .navbar-nav > li {
    float: left;
  }
  .navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.navbar-nav > li > .dropdown-menu {
  margin-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
@media (min-width: 1026px) {
  .navbar-right {
    float: right !important;
    margin-right: -15px;
  }
  .navbar-right ~ .navbar-right {
    margin-right: 0;
  }
}
.navbar-default {
  background-color: #f8f8f8;
  border-color: #e7e7e7;
}

.navbar-default .navbar-brand {
  color: #777777;
}

.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
  color: #5e5e5e;
  background-color: transparent;
}

.navbar-default .navbar-nav > li > a {
  color: #777777;
}

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
  color: #333333;
  background-color: transparent;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  color: #555555;
  background-color: #e7e7e7;
}

.navbar-default .navbar-nav > .disabled > a,
.navbar-default .navbar-nav > .disabled > a:hover,
.navbar-default .navbar-nav > .disabled > a:focus {
  color: #cccccc;
  background-color: transparent;
}

.navbar-default .navbar-toggle {
  border-color: #dddddd;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #dddddd;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: #888888;
}

.navbar-default .navbar-collapse {
  border-color: #e7e7e7;
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  background-color: #e7e7e7;
  color: #555555;
}

@media (max-width: 768px) {
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #777777;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #333333;
    background-color: transparent;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a,
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #555555;
    background-color: #e7e7e7;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a,
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #cccccc;
    background-color: transparent;
  }
}

.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after,
.nav:before,
.nav:after,
.navbar:before,
.navbar:after,
.navbar-header:before,
.navbar-header:after,
.navbar-collapse:before,
.navbar-collapse:after {
  content: " ";
  display: table;
}

.clearfix:after,
.container:after,
.container-fluid:after,
.row:after,
.nav:after,
.navbar:after,
.navbar-header:after,
.navbar-collapse:after {
  clear: both;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}

.hidden {
  display: none !important;
}

.affix {
  position: fixed;
}

@-ms-viewport {
  width: device-width;
}
@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 769px) and (max-width: 1025px) {
  .hidden-sm {
    display: none !important;
  }
}

.navbar-default {
  background-color: #00529b;
  border-color: rgba(34, 34, 34, 0.05);
  border: 0;
  font-family: "gentona", Arial, sans-serif;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
}

.navbar-default .logo {
  display: inline-block;
  float: left;
  background-color: #00529b;
  padding: 5px;
}

.navbar-default .logo .symbol img {
  height: 55px;
  width: 55px;
}

.navbar-default .logo:hover,
.navbar-default .logo:focus {
  background-color: #084b81;
}

.navbar-default .navbar-toggle {
  background-color: #dd661e;
  border-radius: 0;
  margin: 0;
  border: 0;
  padding: 0;
  padding: 18px;
  height: 65px;
  width: 65px;
}

.navbar-default .navbar-toggle > .icon-bar {
  background-color: #ffffff;
  width: 30px;
  height: 2px;
  margin-bottom: 6px;
  transition: all 0.2s;
}

.navbar-default .navbar-toggle > .icon-bar.top-bar {
  transform: translateX(3px) rotate(45deg);
  transform-origin: 10% 10%;
}

.navbar-default .navbar-toggle > .icon-bar.middle-bar {
  opacity: 0;
}

.navbar-default .navbar-toggle > .icon-bar.bottom-bar {
  transform: translateX(3px) rotate(-45deg);
  transform-origin: 10% 90%;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #c75c1b;
}

.navbar-default .navbar-toggle.collapsed .top-bar {
  transform: rotate(0);
}

.navbar-default .navbar-toggle.collapsed .middle-bar {
  opacity: 1;
}

.navbar-default .navbar-toggle.collapsed .bottom-bar {
  transform: rotate(0);
}

.navbar-default .navbar-header .navbar-brand {
  color: #ffffff;
  padding: 24px 0px 0px 15px;
  letter-spacing: 1px;
  font-family: "gentona", Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.navbar-default .navbar-header .navbar-brand:hover,
.navbar-default .navbar-header .navbar-brand:focus {
  color: #e6e6e6;
}

@media only screen and (max-width: 1025px) {
  .navbar-default .navbar-collapse {
    max-height: 100% !important;
    height: 100vh !important;
  }
}
.navbar-default .nav {
  margin-top: 0;
  margin-bottom: 0;
}

.navbar-default .nav > li {
  background-color: #ffffff;
  border-top: 1px dotted #f3efde;
}

.navbar-default .nav > li:nth-child(2n) {
  background-color: #faf8f1;
}

.navbar-default .nav > li:hover {
  background-color: #f7f4e9;
}

.navbar-default .nav > li > a,
.navbar-default .nav > li > a:focus {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  color: #00529b;
}

.navbar-default .nav > li:active {
  background-color: #f3efde;
}

.navbar-default .nav > li.active > a,
.navbar-default .nav > li.active > a:focus {
  color: #dd661e;
  background-color: transparent;
}

.navbar-default .nav > li.active > a:hover,
.navbar-default .nav > li.active > a:focus:hover {
  background-color: transparent;
}

.navbar-default .nav > li .multi-column-dropdown li {
  list-style: none;
  padding: 0.2em 0;
}

.navbar-default .nav > li .multi-column-dropdown li:nth-child(n+2) {
  border-top: 1px solid #f3efde;
}

.navbar-default .nav > li .multi-column-dropdown li a {
  color: #00529b;
  font-family: "quadonmedium", Arial, serif;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
}

.navbar-default .nav > li .multi-column-dropdown li a:hover {
  color: #084b81;
}

.navbar-default .nav > li .multi-column-dropdown li h3 {
  padding-bottom: 0;
  margin-bottom: 0;
  color: #dd661e;
  font-weight: 400;
}

@media (min-width: 1026px) {
  .navbar-default {
    background-color: #fff;
  }
  .navbar-default .navbar-header .navbar-brand {
    color: #00529b;
    padding: 24px 0px 0px 15px;
    letter-spacing: 1px;
    font-family: "gentona", Arial, sans-serif;
    font-weight: 700;
    text-transform: uppercase;
  }
  .navbar-default .navbar-header .navbar-brand:hover,
  .navbar-default .navbar-header .navbar-brand:focus {
    color: #003768;
  }
  .navbar-default .logo {
    margin-right: 1em;
    padding-top: 6px;
  }
  .navbar-default .nav > li {
    background-color: transparent;
    padding-top: 6px;
  }
  .navbar-default .nav > li > ul.dropdown-menu {
    position: fixed;
    top: 65px;
    min-width: 600px;
    border: none;
    padding: 1em;
    padding-right: 55px;
    background-color: #ffffff;
  }
  .navbar-default .nav > li > ul.dropdown-menu img {
    margin: 1em 0;
  }
  .navbar-default .nav > li > ul.dropdown-menu li {
    padding: 1em 0;
  }
  .navbar-default .nav > li > ul.dropdown-menu li a {
    font-size: 18px;
    font-weight: 400;
  }
  .navbar-default .nav > li.search-icon {
    background-color: #dd661e;
    -webkit-transition: all 0.35s;
    -moz-transition: all 0.35s;
    transition: all 0.35s;
    padding: 5px 5px;
  }
  .navbar-default .nav > li.search-icon:hover {
    background-color: #b05118;
  }
  .navbar-default .nav > li.search-icon a > i {
    color: #ffffff;
  }
  .navbar-default .nav > li.search-icon a:hover {
    border-bottom: 0 !important;
  }
  .navbar-default .nav > li.search-icon input {
    background-color: transparent;
    border: none;
    color: rgba(221, 102, 30, 0.7);
    padding: 0.5em;
    width: 88%;
    font-size: 1.65em;
    font-weight: 700;
  }
  .navbar-default .nav > li > a,
  .navbar-default .nav > li > a:focus {
    color: #222222;
    background-color: transparent;
    transition: none;
    padding-bottom: 20px;
  }
  .navbar-default .nav > li > a:hover,
  .navbar-default .nav > li > a:focus:hover {
    color: #dd661e;
    border-bottom: 4px solid #dd661e;
  }
  .navbar-default:hover {
    background-color: #ffffff;
  }
}
@media (min-width: 1026px) {
  #mainNav > div {
    padding-left: 0;
  }
}
.close {
  font-weight: 700;
  font-size: 28px;
  margin-left: 5px;
  cursor: pointer;
}

.alert:first-of-type {
  margin-top: 66px;
}

@media only screen and (max-width: 768px) {
  .alert:first-of-type {
    margin-top: 65px;
  }
}
.alert[data-expires] {
  visibility: visible;
}

#start {
  height: 100vh;
}

ul.breadcrumb > li {
  font-size: 16px;
  font-weight: 700;
  font-family: "gentona", Arial, sans-serif;
}

ul.breadcrumb > li > a:hover {
  text-decoration: none;
}

.navbar-toggle > * {
  pointer-events: none;
}

.skip-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 2;
  background: #2f639d;
  border: none;
  border-bottom: 1px dotted #fff;
  color: #fff;
  font-weight: 700;
  font-size: 2rem;
  text-decoration: none;
  letter-spacing: 0.04em;
  left: 0;
  height: 66px;
  padding: 0.6em;
  position: absolute;
  transform: translateY(-102%);
  transition: transform 0.3s;
}
.skip-link:focus {
  transform: translateY(0%);
  color: #fff;
  border-bottom: 1px solid #fff;
}
.skip-link:visited, .skip-link:active {
  color: #fff;
}

.ufl-text--white {
  color: #f7f9fb;
}

.ufl-non-critical {
  display: none;
  margin: 0 auto;
}

.ufl-critical {
  display: block;
}

.ufl-nav-spacing {
  margin-top: 66px;
}

.ufl-center {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 100%;
}

.ufl-text-center {
  text-align: center;
}

html {
  height: 100%;
}

body {
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: #f7f9fb;
  height: 100%;
}

.visually-hidden {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(0px 0px 99.9% 99.9%);
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
}